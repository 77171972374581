<template>
    <v-container fluid  class="pa-0"> 
        <v-card rounded="false" class="step-header" flat elevation="0" color="ml_blue" width="100%" :min-height="$vuetify.breakpoint.mdAndUp ? (stepDetails.currentStep === 1 && showPolicyLookup() ? 226 : 183) : (stepDetails.currentStep === 1 && showPolicyLookup() ? 256 : 200) " v-bind:style="{ backgroundImage: 'url(' + headingBackgroundSVG + ')' }">
            <v-sheet color="ml_purple" v-if="stepDetails.currentStep === 1 && showPolicyLookup()" >
                <v-container>
                    <v-row>
                        <v-col cols="12" class="d-flex flex-row align-center white--text py-1">
                            <v-icon class="white--text mr-2">mdi-information</v-icon>
                            Looking for a previously purchased policy?
                            <v-btn text large class="btn-text-reset  btn-text-underline py-2 px-1 white--text"  @click="goToEmployeerMyPets"> Click here</v-btn>    
                        </v-col>
                    </v-row> 
                </v-container>
                
            </v-sheet>
            
            <v-card-text class="white--text">
                <v-container>
                    
                    <v-row class="d-flex justify-center align-end">
                        <v-col cols="12" :class="$vuetify.breakpoint.mdAndUp ? 'pt-10' : 'pt-2'" class="d-flex flex-row align-start justify-center">
                            <div class="mr-2 d-flex flex-column">
                                <div>
                                    <h1 class="text-center" tabindex="-1" ref="stepMainHeading">
                                    {{ currentPage !== "Order Confirmation" ? stepHeading : ''}}
                                    </h1>
                                    <h1 role="none" v-if="stepDetails.stepSubHeading">
                                        <small class="d-block pt-3 font-weight-light">{{stepDetails.stepSubHeading}}</small>
                                    </h1>
                                </div>
                                <v-spacer></v-spacer>
                                <h4 role="none" id="partner-phone-contact" class="contact-banner-partner font-weight-regular text-center" v-if="contactInfo !== null &&  $vuetify.breakpoint.smAndDown" >
                                    Need help? Call:
                                    <a aria-labelledby="partner-phone-contact" class="white--text " :href="'tel:'+contactInfo" v-on:click="trackAdobeEvent()">   {{this.getNumbertoAlphanumberic(contactInfo)}}</a>
                                </h4>
                            </div>
                            <!-- <v-icon v-if="$vuetify.breakpoint.smAndDown" class="xxxx-large ">{{ getImageSrc(imageName) }}</v-icon> -->
                            <v-img 
                                alt="Snoopy"
                                class="shrink side-card-img"
                                contain
                                :src="this.imageName"
                                transition="scale-transition"
                                max-height="93"
                                max-width="93"
                                width="auto"
                                v-if="imageName !== '' && $vuetify.breakpoint.smAndDown"
                            />
                        </v-col>
                        
                    </v-row>
                </v-container>
            </v-card-text>
            <!-- <v-card-actions class="pa-0">
                <v-progress-linear ref="progressBar" height="10" :aria-valuetext="'Step ' + stepDetails.currentStep" :aria-label=" 'Step ' + stepDetails.currentStep +' of '+ stepDetails.totalSteps +': '+ stepDetails.stepHeading +' '+ Math.floor(100 * (stepDetails.currentStep / stepDetails.totalSteps)) +'%'" background-color="grey lighten-2" color="gradient" :value="Math.floor(100 * (stepDetails.currentStep / stepDetails.totalSteps))" ></v-progress-linear>
            </v-card-actions> -->
        </v-card>
    </v-container>
</template>
<script>
export default {
    name: 'stepHeader',
    props: {
        stepDetails: null,
        stepHeading: {
            type: String,
            default: null
        }, 
        contactInfo: {
            type: String,
            default: null
        }, 
        imageName: {
            type: String, 
            required: true
        }
    },
    data: () => {
        return{
            headingBackgroundSVG :  require ('@/assets/svg/stepHeader.svg'),
        }
    },
    computed: {
        partnerId: function () {
            return this.$store.getters.partnerId;
        },
        employeeToken: function() {
            return this.$store.getters.employeeToken;
        },
        leadId: {
            get() {
                return this.$store.getters.leadId;
            }
        },
        currentPage(){
            return this.$store.getters.getCurrentPage;            
        },
    },
    methods: {
        calculatePercentage: function (steps, current) {
            return (current * 100) / steps; 
        },
       
        focus() {
            const interval = setInterval(()=>{
                if(this.$refs.stepMainHeading) {
                    this.$nextTick(()=>this.$refs.stepMainHeading.focus());
                    clearInterval(interval);
                }
            },50);
        },
        trackAdobeEvent() {
            let ldo = {
                eventName: 'linkClick',
                attributes: {
                    application: 'MLPI',
                    form: 'Pet Insurance Quote',
                    linkName: this.contactInfo,
                    leadId: this.leadId
                }
            };
            this.$store.dispatch("trackAnalyticsEvent", {
                eventType: 'linkClick',
                ldo: ldo
            });
        },
      
        goToEmployeerMyPets(){
            let ldo = {
                eventName: 'quoteSwitchButton',
                attributes: {
                    application: 'MLPI',
                    form: 'Pet Insurance Quote',
                    leadId: this.leadId,
                    quoteSwitchValue: 'Employer MyPets'
                }
            };
            this.$store.dispatch("trackAnalyticsEvent", {
                eventType: "quoteSwitchButton",
                ldo: ldo
            });
            let emptoken = this.employeeToken;
            sessionStorage.clear();
            this.$store.reset();
            window.location.replace(process.env.VUE_APP_MYPETS_BASE_URL + '/policy/linkpolicy?token=' + emptoken);
        },
        showPolicyLookup(){
            return this.$route.query["token"] ? true : false;
        }
        
    },
    mounted() {
    },
    updated() {
    }   
}
</script>
<style scoped>
    .v-card.step-header{
        border-radius: 0px;
    }
</style>