<template>
    <div :class="this.loading ? '' : 'd-sr-only'">
    <div aria-live="polite" ref="politeLoader">
        <v-overlay :absolute="isAbsolute" color="white" v-if="show">
            <div class="dot-typing"></div>
            <span v-if="loaderText" class="dot-text" id="dot_text">{{loaderText}}</span>
            <span v-else class="d-sr-only">Loading... Please Wait!</span>
        </v-overlay> 
    </div>
    <div class="d-sr-only" aria-live="polite">
        <div v-if="reannounce">
            <span v-if="loaderText">{{loaderText}}</span>
            <span v-else>Loading... Please Wait!</span>
        </div>

    </div>
</div>
</template>
<script>
export default {
    name: 'dotloader',
    props: {
        onLoadingComplete: Function,
        loaderText: String,
        loading: Boolean, 
        isAbsolute: {
            type: Boolean, 
            default: true
        }
    },
    data:() => ({
        show: false
    }),
    mounted() {
        //If loader to be shown on mount
        if(this.loading) {
            //Promise to Show loader text with slight delay
            this.showed = new Promise((resolve, reject)=>{
                setTimeout(()=>{
                    this.show = this.loading;//true
                    this.$store.commit("setDotLoader", true);
                    resolve();
                }, 300);
            });
        }
    },
    updated() {
        //If loader to be shown
        if(this.loading) {
            //Promise to Show loader text with slight delay
            this.showed = new Promise((resolve, reject)=>{
                setTimeout(()=>{
                    this.show = this.loading;//true
                    this.$store.commit("setDotLoader", true);
                    resolve();
                }, 300);
            });
        }
        //If shown loader to be hidden
        if(this.showed && !this.loading) {
            this.showed.then(()=> {
                setTimeout(()=>{
                    this.show = this.loading;//false
                    this.showed = null;
                    this.$nextTick(()=> {
                        this.$store.commit("setDotLoader", false);
                        this.onLoadingComplete();
                    });
                }, 500);

            });
        }
    },
    computed: {
        reannounce() {
        //if shown loader to be renotified
        //Ensure that dotloader is already shown
        if(this.loading) {
            //if to be reannounced
            if(this.$store.state.reannounceDotLoader === true) {
                //ensure reannouncement will be cleared once reannounced
                setTimeout(() => {
                    this.$store.commit("setReannounceDotLoader", false);
                }, 100);
                return true;//to reannounce
            }
        }
        //if dotloader is not shown, no reannouncement needed.
        if(!this.loading) {
            this.$store.commit("setReannounceDotLoader", false);
            return false;
    }
        }
    }
}
</script> 