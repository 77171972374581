<template>
    <div class="section-wrap">
        <DotLoader :loading="loading" :loaderText="loaderText" :onLoadingComplete="handleLoaderComplete" />
        <StepHeader :stepDetails=stepHeaderConfig :stepHeading="titleHeading" :imageName="step3SideImgSource" ref="stepHeader"></StepHeader>
        <div class="step-body">
            <div class="form-inner">
                <v-container>
                    <v-row justify="center">
                        <v-col cols="12">
                            <v-card flat class="spacing-playground v-card-form">
                                <v-card-text>
                                    <v-row justify="center">
                                        <v-col cols="12" md="7" class="form-col-section">
                                            <div class="section-heading2">
                                                    <h2>Retrieve Your Quote</h2>  
                                            </div>
                                            <p class="body-1">Welcome back! Please enter the email associated with your previously created quote</p>
                                            <v-text-field
                                                label="Email Address"
                                                :rules="emailErrors"
                                                hint="Required."    
                                                type="email"
                                                v-model="userEmail"
                                                persistent-hint
                                                clearable
                                            >

                                            </v-text-field>

                                            <vue-recaptcha
                                                class="mt-2"
                                                ref="recaptcha"
                                                :sitekey="siteKey"
                                                @verify="onCaptchaVerified"
                                                badge="inline"
                                                size="invisible"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="5" class="side-col-section" v-show="$vuetify.breakpoint.smAndUp">
                                                <v-card color="ml_lightgray" outlined elevation="0"  class="side-card">
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="10" offset="1" class="text-center">
                                                                <v-card-title>
                                                                    <v-img 
                                                                        alt="Snoopy"
                                                                        class="shrink side-card-img"
                                                                        contain
                                                                        :src="step3SideImgSource"
                                                                        transition="scale-transition"
                                                                        max-height="212"
                                                                        width="auto"
                                                                        v-if="step3SideImgSource !== ''"
                                                                    />
                                                                    <p role="heading" aria-level="2">
                                                                        We automatically save previous quotes when you configure your pet insurance
                                                                    </p>
                                                                    
                                                                </v-card-title>
                                                              
                                                                <v-card-actions class="d-flex flex-column black--text">
                                                                    <v-btn block x-large class="nextBtnFocus" color="ml_purple" @click="isValidEmail" :loading="loading" :disabled="loading">
                                                                        Retrieve Your Quote
                                                                        <v-icon small>mdi-play</v-icon>
                                                                    </v-btn>

                                                                    <p class="pt-3 pb-7 support-phone">Or call: <a style="color:#006BDD !important" :href="'tel:'+this.getPartnerContactInfo()" v-on:click="trackAdobeEventPhone()"> {{this.getNumbertoAlphanumberic(this.getPartnerContactInfo())}}</a></p>
                                                                   
                                                                </v-card-actions>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>    
                                            </v-col>
                                    </v-row>
                                </v-card-text>
                               
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        
        <v-footer class="v-footer" ref="controlZoomLevel" app color="white" elevation="4" role="none">
            <v-row class="d-flex justify-center align-center">
                <v-col cols="auto">
                    <v-btn :disabled="loading" @click="goBack">Back</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="ml_green" tabindex="0" @click="isValidEmail" :loading="loading" :disabled="loading">
                        Retrieve Your Quote <v-icon class="mt-n1">mdi-menu-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-footer>
    </div>
</template>

<script>
    import StepHeader from '../../components/challenger/shared/StepHeader.vue';
    import DotLoader from '../../components/shared/DotLoader.vue';
    import { VueRecaptcha } from 'vue-recaptcha';
    export default {
        components: { DotLoader, StepHeader, VueRecaptcha },
        data () {
            return {
                siteKey: process.env.VUE_APP_GOOGLE_SITE_KEY,
                loading: false,
                loaderText: "",
                stepHeaderConfig: [],
                titleHeading: "Are you a returning visitor? Continue where you left off.",
                btnDisabled: false, 
                emailErrors: [],
                userEmail: '',
                recaptchaToken: null,
            }
        }, 
        mounted() {
        },
        created() {
            this.stepHeaderConfig = {
                stepHeading: "Are you a returning visitor? Continue where you left off.",
                currentStep: 1,
                totalSteps: 5
            };

            let intervalId = setInterval(() => {
                if (this.defaultSidebarImage || this.dynamicSidebarImage) {
                    this.setImageSource(this.defaultSidebarImage, this.dynamicSidebarImage, "3", "setStep3SideImgSource");
                    clearInterval(intervalId);
                }
            }, 1000);

            setTimeout(() => clearInterval(intervalId), 10000);
        },
        computed: {
            defaultDynamicContent() {
                return this.$store.getters.getDefaultDynamicContent.DynamicJson;
            },
            dynamicContent() {
                return this.$store.getters.getDynamicContent.DynamicJson;
            },
            step3SideImgSource(){
                return this.$store.getters.getStep3SideImgSource;
            },
            isMetGen(){
                return this.$store.getters.isMetGen;
            },
            partner: function() {
                return this.$store.getters.partner;
            },
            employeeToken: function() {
                return this.$store.getters.employeeToken;
            },
            leadId: {
                get() {
                    return this.$store.getters.leadId;
                }
            },
            defaultSidebarImage() {
                const images = this.$store.getters.getDefaultDynamicContent.SidebarImages;
                return images && images.length > 0 ? images.find(image => image.SeqNum === 2) : null;
            },
            dynamicSidebarImage() {
                const images = this.$store.getters.getDynamicContent.SidebarImages;
                return images && images.length > 0 ? images.find(image => image.SeqNum === 2) : null;
            },
        },
        methods: {
            onCaptchaVerified(token) {
                this.emailErrors = [];
                this.recaptchaToken = token;
                this.fetchQuoteByEmail();
            },
            onCaptchaExpired() {
                this.recaptchaToken = null;
                this.emailErrors.push("Please verify the reCAPTCHA.");
            },
            onCaptchaError() {
                this.recaptchaToken = null;
                this.emailErrors.push("An error occurred during reCAPTCHA verification. Please try again.");
            },
            handleLoaderComplete() {
                if(this.onLoderComplete) {
                    this.onLoderComplete();
                    this.onLoderComplete = () => {};
                }
            },
            redirectToView(step) {
                this.routerPush(step);
            },
            goBack() {
                this.$store.commit("setCurrentPage","Pet Info");
                this.redirectToView("pet");
            },
            checkFormat(str){
                const emailRegex = /.+@.+/;
                const lettersRegex = /^[a-zA-Z0-9\s@.]+$/;

                return emailRegex.test(str) && lettersRegex.test(str);
            },
            isValidEmail(){ 
                const email = this.userEmail;
                const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,12})+$/;
                const validMail = emailRegex.test(email);
                const errors = [];

                if (email === '') {
                    errors.push("Please enter your email (Required).");
                } else if (!validMail) {
                    errors.push("Please enter a valid email address.");
                } else {
                    this.$refs.recaptcha.execute();
                }
                
                this.emailErrors = errors;
            },
            async fetchQuoteByEmail() {
                var self = this;
                this.loading = true;

                await this.$store.dispatch("getLeadByEmail", { email: this.userEmail, token: this.recaptchaToken })
                    .then(async (response) => {
                        this.$store.commit("setOwnerEmail", this.userEmail);
                        if (response) {
                            var ldo = {
                                eventName: 'formComplete',
                                attributes: {
                                    application: 'MLPI',
                                    form: 'Fetch Quote',
                                    linkName: 'Fetch your Previous Quote',
                                    stepName: 'Pet Info'
                                }
                            }
                            digitalData.eventTrack('formComplete', ldo);
                            
                            this.$store.commit("saveLeadId", response.id);
                            await this.$store.dispatch("getLeadId")
                                .then(response => {
                                    self.populateStepFieldsLeadCapture(response[0]);
                                    self.redirectToStep(self.$store.state.lastStep);
                                    setTimeout(() => {this.loading = false}, 4000);
                            });

                            this.$refs.recaptcha.reset();
                        } else {
                            const errors = [];
                            errors.push('No quotes saved with this email address');
                            this.emailErrors = errors;

                            this.loading = false;

                            var ldo = {
                                eventName: 'formError',
                                attributes: {
                                    application: 'MLPI',
                                    form: 'Fetch Quote',
                                    linkName: 'Fetch your Previous Quote',
                                    errorMsg: 'No quotes saved with this email address',
                                    stepName: 'Pet Info'
                                }
                            }
                            digitalData.eventTrack('formError', ldo);

                            this.$refs.recaptcha.reset();
                        }
                    });
            },
            populateStepFieldsLeadCapture(leadResponse) {
                const petDetails = {
                    name: leadResponse.name,
                    color: leadResponse.colorId,
                    age: leadResponse.age,
                    species: leadResponse.speciesId,
                    gender: leadResponse.gender === "F" ? 2 : 1,
                    breed: leadResponse.breedId
                };
                this.$store.commit('updatePet', petDetails);
            },
            redirectToStep(step) {
                this.$store.dispatch("getUserState")
                    .then(async () => {
                        if (step && step != "pet") {
                            if(this.isMetGen && this.partner.billingOptions == 'both' && this.employeeToken){
                                this.$store.commit("setPayOption", 'both');

                                this.$store.commit("setStepActive", "pricing");
                                this.$store.commit("setStepComplete", "pet");

                                let ldo = {
                                    eventName: 'quoteStep',
                                    attributes: { 
                                    application: 'MLPI',
                                        form: 'Pet Insurance Quote',
                                        stepName: 'Pet Info',
                                        leadId: this.leadId
                                    }
                                };
                                this.$store.dispatch("trackAnalyticsEvent",{
                                    eventType: "quoteStep",
                                    ldo: ldo
                                });

                                if (this.$store.getters.partner.id) this.$route.query["partnerId"] = 999999;
                                this.$route.query["enrollment_code"] = this.$store.getters.enrollmentCode;
                                this.redirectToView("quotes");
                            }else{
                                if(this.employeeToken && !this.isMetGen && this.partner.billingOptions === 'both'){
                                    this.$store.commit("setPayOption", 'both');
                                    this.$store.commit("setIAICPayOptionSetUp", true);
                                }
                                this.$store.commit("setStepActive", "pricing");
                                this.$store.commit("setStepComplete", "pet");
                                let ldo = {
                                    eventName: 'quoteStep',
                                    attributes: { 
                                    application: 'MLPI',
                                        form: 'Pet Insurance Quote',
                                        stepName: 'Pet Info',
                                        leadId: this.leadId
                                    }
                                };
                                this.$store.dispatch("trackAnalyticsEvent",{
                                    eventType: "quoteStep",
                                    ldo: ldo
                                });

                                if (this.$store.getters.partner.id) this.$route.query["partnerId"] = 999999;
                                this.$route.query["enrollment_code"] = this.$store.getters.enrollmentCode;
                                this.redirectToView("quotes");
                            }
                        }
                    })
                    .catch(err => {
                        var ldo = {
                            eventName: 'quoteError',
                            attributes: {
                                application: 'MLPI',
                                form: 'Pet Insurance Quote',
                                stepName: 'Pet Info',
                                ErrorMessage: err.message,
                                leadId: this.leadId,
                                petType: this.getPetSpecie(this.currentPet.species)
                            }
                        };

                        ldo = this.popullateFederalAnalyticProperty(ldo);

                        digitalData.eventTrack('quoteError', ldo);
                    });
            },
        }
    }
</script>